<template>
  <div class="audit">
    <!--  -->
    <div class="top">
      <div class="df-row">
        <div class="date-picker">
          <el-date-picker
            v-model="beginAndEnd"
            size="small"
            type="date"
            align="left"
            unlink-panels
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
            @change="onSelectDate"
          >
          </el-date-picker>
        </div>

        <div>
          <el-select
            v-model="deptGuid"
            placeholder="消费部门"
            size="small"
            clearable
          >
            <el-option
              v-for="item in deptList"
              :key="item.DeptGuid"
              :label="item.DeptName"
              :value="item.DeptGuid"
            >
            </el-option>
          </el-select>
        </div>

        <div>
          <el-select
            v-model="billType"
            placeholder="单据类型"
            size="small"
            clearable
          >
            <el-option
              v-for="item in billTypeList"
              :key="'type' + item.Value"
              :label="item.Name"
              :value="item.Value"
            >
            </el-option>
          </el-select>
        </div>

        <div>
          <el-select
            v-model="paymentGuid"
            placeholder="付款方式"
            size="small"
            clearable
          >
            <el-option
              v-for="item in paymentList"
              :key="item.PaymentGuid"
              :label="item.PaymentName"
              :value="item.PaymentGuid"
            >
            </el-option>
          </el-select>
        </div>

        <div>
          <el-select
            v-model="storesShare"
            placeholder="跨店消费"
            size="small"
            clearable
          >
            <el-option
              v-for="item in storesShareList"
              :key="'stores' + item.Value"
              :label="item.Name"
              :value="item.Value"
            >
            </el-option>
          </el-select>
        </div>

        <div>
          <el-select
            v-model="admin"
            placeholder="操作人"
            size="small"
            clearable
          >
            <el-option
              v-for="item in menagerAdminList"
              :key="item.ManagerGuid"
              :label="item.ManagerName"
              :value="item.ManagerGuid"
            >
            </el-option>
          </el-select>
        </div>

        <div>
          <el-select
            v-model="auditing"
            placeholder="是否对单"
            size="small"
            clearable
          >
            <el-option
              v-for="item in checkMarkList"
              :key="'mark' + item.Value"
              :label="item.Name"
              :value="item.Value"
            >
            </el-option>
          </el-select>
        </div>

        <div>
          <el-select v-model="deleteMark" placeholder="请选择" size="small">
            <el-option
              v-for="item in exceptionSheetQuery"
              :key="'es' + item.Value"
              :label="item.Name"
              :value="item.Value"
            >
            </el-option>
          </el-select>
        </div>

        <div>
          <el-select
            v-model="emplKey"
            value-key="Key"
            placeholder="选择员工"
            size="small"
            @change="onChangeEmpl"
          >
            <el-option-group
              v-for="(dutyItem, dutyIndex) in dutyEmpls"
              :key="dutyItem.DutyNo"
              :label="dutyItem.DutyName"
            >
              <el-option
                v-for="(emplItem, emplIndex) in dutyItem.List"
                :key="emplItem.Key"
                :label="
                  emplItem.Key
                    ? `${emplItem.EmplNo} - ${emplItem.EmplName}`
                    : emplItem.EmplName
                "
                :value="emplItem"
              >
              </el-option>
            </el-option-group>
          </el-select>
        </div>

        <div class="select-empl">
          <el-checkbox v-model="isCheckEmpl" @change="onChangeCheckEmpl"
            >仅显示此员工项目</el-checkbox
          >
        </div>
      </div>
    </div>

    <!--  -->
    <div class="statistical">
      <el-collapse accordion>
        <el-collapse-item>
          <template slot="title">
            <div class="summary_number">
              本次共查询
              <span class="value">{{ summary.BillCount }}</span>
              张水单，劳动业绩：
              <span class="value">{{ summary.WorkPerfor }}</span>
              元， 疗程劳动业绩
              <span class="value">{{ summary.WorkPackPerfor }}</span>
              元，卖品业绩：
              <span class="value">{{ summary.SellPerfor }}</span>
              元， 开、充卡：
              <span class="value">{{ summary.OpenKeepTotalPerfor }}</span>
              元， 套餐销售：
              <span class="value">{{ summary.OpenPackCardPerfor }}</span>
              元， 还款业绩：
              <span class="value">{{ summary.RepayPerfor }}</span>
              元
              <span class="text-small">明细>></span>

              <div
                class="alone-dona"
                style="margin-left: 20px;"
                v-show="aloneDonaItemList.length > 0"
              >
                本次查询含有{{ aloneDonaItemList.length }}个单独赠送项目
                <span
                  class="text-small alone-dona"
                  @click.stop="onShowAloneDonaItemDialog"
                  >明细>></span
                >
              </div>
            </div>

            <!-- <div
              class="summary_number alone-dona"
              style="flex: .4;"
              v-show="aloneDonaItemList.length > 0"
            >
              本次查询含有{{ aloneDonaItemList.length }}个单独赠送项目
              <span
                class="text-small alone-dona"
                @click.stop="onShowAloneDonaItemDialog"
                >明细>></span
              >
            </div> -->

            <div class="btn-box" @click.stop="">
              <el-input
                v-model="word"
                placeholder="请输入名称、手机号、流水号、卡号"
                size="small"
              ></el-input>

              <el-button @click="onSearchBill" type="primary" size="small"
                >查询</el-button
              >

              <el-button
                @click.stop="onSoftWareDate"
                type="primary"
                size="small"
                >修改记账日期</el-button
              >
            </div>
          </template>
          <div class="text">
            <strong>劳动业绩：</strong>
            <span v-for="(item, index) in summary.WorkPaymentList" :key="index">
              {{ item.PaymentName }}：
              <i class="value">{{ item.PayMoney }}</i>
            </span>
          </div>
          <div class="text">
            <strong>卖品业绩：</strong>
            <span v-for="(item, index) in summary.SellPaymentList" :key="index">
              {{ item.PaymentName }}：
              <i class="value">{{ item.PayMoney }}</i>
            </span>
          </div>
          <div class="text">
            <strong>套餐销售：</strong>
            <span v-for="(item, index) in summary.PackPaymentList" :key="index">
              {{ item.PaymentName }}：
              <i class="value">{{ item.PayMoney }}</i>
            </span>
          </div>
          <div class="text">
            <strong>开、充卡：</strong>
            <span
              v-for="(item, index) in summary.OpenKeepPaymentList"
              :key="index"
            >
              {{ item.PaymentName }}：
              <i class="value">{{ item.PayMoney }}</i>
            </span>
          </div>
          <div class="text">
            <strong>还款业绩：</strong>
            <span
              v-for="(item, index) in summary.RepayPaymentList"
              :key="index"
            >
              {{ item.PaymentName }}：
              <i class="value">{{ item.PayMoney }}</i>
            </span>
          </div>
          <div class="text">
            <strong>汇总：</strong>
            <span
              v-for="(item, index) in summary.TotalPaymentList"
              :key="index"
            >
              {{ item.PaymentName }}：
              <i class="value">{{ item.PayMoney }}</i>
            </span>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>

    <!--  -->
    <el-table
      class="level"
      :data="billList"
      :max-height="tableMaxHeight"
      border
      :span-method="objectSpanMethod"
      stripe
      size="mini"
      header-row-class-name="header-row-class-name"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="40"> </el-table-column>
      <el-table-column align="center">
        <template slot="header" slot-scope="head">
          <div class="bill-head">
            <div class="bill-no" @click="onChangeSort('BillNo')">
              <span>单号</span>
              <div class="icon-box">
                <i
                  class="el-icon-caret-top"
                  :class="sortInfo.BillNo ? '' : 'sort-active'"
                ></i>
                <i
                  class="el-icon-caret-bottom"
                  :class="sortInfo.BillNo ? 'sort-active' : ''"
                ></i>
              </div>
            </div>
            <div class="bill-time" @click="onChangeSort('BillTime')">
              <span>时间</span>
              <div class="icon-box">
                <i
                  class="el-icon-caret-top"
                  :class="sortInfo.BillTime ? '' : 'sort-active'"
                ></i>
                <i
                  class="el-icon-caret-bottom"
                  :class="sortInfo.BillTime ? 'sort-active' : ''"
                ></i>
              </div>
            </div>
          </div>
        </template>
        <template slot-scope="scope">
          <p>{{ scope.row.BillNo !== "" ? scope.row.BillNo : "无账单号" }}</p>
          <p>
            {{
              util.dateFormat(
                "YYYY-mm-dd HH:MM:SS",
                new Date(scope.row.BillTime * 1000)
              )
            }}
          </p>
          <p class="color pointer" @click="onEditorNote(scope.row)">
            <template v-if="scope.row.BillNote == ''">
              <span class="note">备注</span>
              <i class="el-icon-edit-outline"></i>
            </template>
            <template v-else>
              <span>{{ scope.row.BillNote }}</span>
            </template>
          </p>
        </template>
      </el-table-column>
      <el-table-column label="客户" align="center">
        <template slot-scope="scope">
          <div>
            <p>{{ scope.row.MemberName }}</p>
            <p>
              <a class="sex" href="javascript:;">{{
                scope.row.BillSex == 0 ? "女" : "男"
              }}</a>
              <span>{{ scope.row.IsNewMember }}</span>
            </p>
            <p>{{ scope.row.MemberPhone }}</p>
            <p>{{ scope.row.VipNo }}</p>
            <p v-if="scope.row.CardName">[{{ scope.row.CardName }}]</p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="记客数" align="center" width="80">
        <template slot-scope="scope">
          <div
            slot="reference"
            class="color pointer"
            @click="onCalcClient(scope.row)"
          >
            {{ scope.row.CalcClientText }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="消费金额" align="center" width="150">
        <template slot-scope="scope">
          <div>
            <div
              v-if="
                scope.row.BillAmount ||
                  scope.row.BillDisc ||
                  scope.row.BillCoupon
              "
              class="total"
            >
              <p v-show="scope.row.BillAmount">
                总金额：{{ scope.row.BillAmount }}
              </p>
              <span v-show="scope.row.BillDisc" class="disc"
                >折扣：{{ scope.row.BillDisc }}</span
              >
              <span v-show="scope.row.BillCoupon" class=""
                >优惠券：{{ scope.row.BillCoupon }}</span
              >
            </div>
            <p class="type">{{ scope.row.BillTypeName }}</p>
            <div>
              <p
                v-for="(item, index) in scope.row.PaymentInfoList"
                :key="item.PaymentGuid"
              >
                <span
                  class="payment-money color"
                  :class="item.PaymentType == 1 ? 'active' : ''"
                  >{{ item.PayMoney }}</span
                >
                <span :class="item.PaymentType == 1 ? 'active' : ''">{{
                  item.PaymentName
                }}</span>

                <span style="color: #c58356;" v-if="item.VipNote">
                  <br />
                  <span>备注:{{ item.VipNote }}</span>
                </span>

                <span v-if="item.PayMoneyDetails">
                  {{ item.PayMoneyDetails }}
                </span>
              </p>
            </div>
            <div>
              <p
                v-for="(item, index) in scope.row.VipItemCardList"
                :key="item.VipGuid"
              >
                <span>{{ item.CardName }}</span>
              </p>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="消费内容" align="center" width="150">
        <template slot-scope="scope">
          <div class="second-level">
            <el-table
              v-if="scope.row.ItemInfoList.length > 0"
              :data="scope.row.ItemInfoList"
              :show-header="false"
              :border="false"
              :span-method="objectSpanMethodItem"
              size="mini"
              class="custom-table"
              v-loading="loading"
            >
              <el-table-column align="center" width="150">
                <template slot-scope="scope1">
                  <div
                    v-if="scope1.row.ItemType == 9"
                    v-html="changeExtension(scope1.row.Extension)"
                  ></div>

                  <div v-else>
                    <p>
                      <span>{{ scope1.row.ItemName }}</span>
                      <span> ¥{{ scope1.row.ItemAmount }}</span>
                    </p>
                    <p class="color" v-if="scope1.row.ItemTypeText">
                      ({{ scope1.row.ItemTypeText }})
                    </p>
                    <p
                      class="dept-name"
                      @click="onShowEditDept(scope.row, scope1.row)"
                    >
                      {{ scope1.row.DeptName || "无部门" }}
                      <i class="el-icon-edit-outline"></i>
                    </p>
                  </div>

                  <p
                    class="dept-name"
                    v-if="scope.row.BillType > 5 && scope.row.BillType < 9"
                    @click="onShowEditPerfor(scope.row, scope.$index)"
                  >
                    {{ scope1.row.DeptName }}
                    <i class="el-icon-edit-outline"></i>
                  </p>
                </template>
              </el-table-column>
              <el-table-column align="center" width="80">
                <template slot-scope="scope2">
                  <div v-if="scope.row.BillType != 9" class="three-level">
                    <el-table
                      :data="scope2.row.EmplInfoList"
                      :show-header="false"
                      :border="false"
                      size="mini"
                      v-if="scope2.row.EmplInfoList.length > 0"
                    >
                      <el-table-column align="center" width="80">
                        <template slot-scope="scope3">
                          <el-popover
                            placement="bottom"
                            trigger="click"
                            v-model="scope3.row.visible_1"
                            :disabled="scope.row.AuditingState != 1"
                          >
                            <div style="padding: 20px">
                              <el-row :gutter="10">
                                <el-col :span="14">
                                  <el-input
                                    v-model="alterPerfor"
                                    placeholder="请输入业绩"
                                    size="small"
                                  ></el-input>
                                </el-col>
                                <el-col :span="10">
                                  <el-button
                                    type="primary"
                                    size="small"
                                    @click="
                                      onConfirmPerfor(
                                        scope3.row,
                                        scope2.row,
                                        scope.row
                                      )
                                    "
                                    >确定</el-button
                                  >
                                  <el-button
                                    type="danger"
                                    size="small"
                                    @click="
                                      onCancelPerfor(
                                        scope3.row,
                                        scope2.row,
                                        scope.row
                                      )
                                    "
                                    >取消</el-button
                                  >
                                </el-col>
                              </el-row>
                            </div>
                            <span class="color pointer" slot="reference">
                              {{ scope3.row.PerforFinal }}
                            </span>
                          </el-popover>
                        </template>
                      </el-table-column>
                      <el-table-column align="center" width="80">
                        <template slot-scope="scope3">
                          <el-popover
                            placement="bottom"
                            trigger="click"
                            v-model="scope3.row.visible_4"
                            :disabled="scope.row.AuditingState != 1"
                          >
                            <div style="padding: 20px">
                              <el-row :gutter="10">
                                <el-col :span="14">
                                  <el-input
                                    v-model="alterRoya"
                                    placeholder="请输入提成"
                                    size="small"
                                  ></el-input>
                                </el-col>
                                <el-col :span="10">
                                  <el-button
                                    type="primary"
                                    size="small"
                                    @click="
                                      onConfirmRoya(
                                        scope3.row,
                                        scope2.row,
                                        scope.row
                                      )
                                    "
                                    >确定</el-button
                                  >
                                  <el-button
                                    type="danger"
                                    size="small"
                                    @click="
                                      onCancelRoya(
                                        scope3.row,
                                        scope2.row,
                                        scope.row
                                      )
                                    "
                                    >取消</el-button
                                  >
                                </el-col>
                              </el-row>
                            </div>
                            <span class="color pointer" slot="reference">
                              {{ scope3.row.RoyaFinal }}
                            </span>
                          </el-popover>
                        </template>
                      </el-table-column>
                      <el-table-column align="center" width="150">
                        <template slot-scope="scope3">
                          <el-popconfirm
                            title="确定删除该员工"
                            @confirm="
                              onDelectEmployee(
                                scope3.row,
                                scope2.row,
                                scope.row
                              )
                            "
                            :disabled="scope.row.AuditingState != 1"
                          >
                            <div slot="reference">
                              <p>
                                <span class="empl-no">{{
                                  scope3.row.EmplNo
                                }}</span>
                                <span>{{ scope3.row.LevelName }}</span>
                              </p>
                              <p class="color pointer">
                                [{{ scope3.row.EmplName }}]
                                <span
                                  v-if="scope2.row.TimeLimit"
                                  @click.stop="
                                    onShowRelate(
                                      scope.row,
                                      scope2.row,
                                      scope3.row
                                    )
                                  "
                                  >{{
                                    `【${
                                      scope2.row.TimeLimit ? "关联" : "计时"
                                    }】`
                                  }}</span
                                >
                              </p>
                            </div>
                          </el-popconfirm>
                        </template>
                      </el-table-column>
                      <el-table-column align="center" width="80">
                        <template
                          slot-scope="scope3"
                          v-if="scope.row.BillType < 6"
                        >
                          <el-popconfirm
                            :title="
                              scope3.row.AssignMark == 1 ? '非指定' : '指定'
                            "
                            @confirm="
                              onAssignEmployee(
                                scope3.row,
                                scope2.row,
                                scope.row
                              )
                            "
                            :disabled="scope.row.AuditingState != 1"
                          >
                            <div slot="reference">
                              <p
                                :class="
                                  scope3.row.AssignMark == 1
                                    ? 'appo pointer'
                                    : 'not-appo pointer'
                                "
                                v-if="scope.row.RecordType == 1"
                              >
                                {{
                                  scope3.row.AssignMark == 1 ? "指定" : "非指定"
                                }}
                              </p>
                            </div>
                          </el-popconfirm>

                          <el-popover
                            placement="bottom"
                            trigger="click"
                            v-model="scope3.row.visible_2"
                            :disabled="scope.row.AuditingState != 1"
                          >
                            <div style="padding: 20px">
                              <el-row :gutter="10">
                                <el-col :span="14">
                                  <el-input
                                    v-model="alterAssignRoya"
                                    placeholder="请输入指定提成"
                                    size="small"
                                  ></el-input>
                                </el-col>
                                <el-col :span="10">
                                  <el-button
                                    type="primary"
                                    size="small"
                                    @click="
                                      onConfirmAssignRoya(
                                        scope3.row,
                                        scope2.row,
                                        scope.row
                                      )
                                    "
                                    >确定</el-button
                                  >
                                  <el-button
                                    type="danger"
                                    size="small"
                                    @click="
                                      onCancelAssignRoya(
                                        scope3.row,
                                        scope2.row,
                                        scope.row
                                      )
                                    "
                                    >取消</el-button
                                  >
                                </el-col>
                              </el-row>
                            </div>
                            <span
                              :class="
                                scope3.row.AssignMark == 1
                                  ? 'appo pointer'
                                  : 'not-appo pointer'
                              "
                              slot="reference"
                            >
                              {{ scope3.row.AssignRoyaFinal }}
                            </span>
                          </el-popover>
                        </template>
                      </el-table-column>
                      <el-table-column align="center">
                        <template
                          slot-scope="scope3"
                          width="120"
                          v-if="scope.row.BillType < 6"
                        >
                          <div v-if="scope3.row.InventMark == 0">
                            {{ scope3.row.InventRoyaFinal }}
                          </div>
                          <el-popover
                            placement="bottom"
                            trigger="click"
                            v-model="scope3.row.visible_3"
                            :disabled="scope.row.AuditingState != 1"
                          >
                            <div style="padding: 20px">
                              <el-row :gutter="10">
                                <el-col :span="14">
                                  <el-input
                                    v-model="alterInventRoya"
                                    placeholder="请输入创单业绩"
                                    size="small"
                                  ></el-input>
                                </el-col>
                                <el-col :span="10">
                                  <el-button
                                    type="primary"
                                    size="small"
                                    @click="
                                      onConfirmInventRoya(
                                        scope3.row,
                                        scope2.row,
                                        scope.row
                                      )
                                    "
                                    >确定</el-button
                                  >
                                  <el-button
                                    type="danger"
                                    size="small"
                                    @click="
                                      onCancelInventRoya(
                                        scope3.row,
                                        scope2.row,
                                        scope.row
                                      )
                                    "
                                    >取消</el-button
                                  >
                                </el-col>
                              </el-row>
                            </div>
                            <span class="color pointer" slot="reference">
                              {{ scope3.row.InventRoyaFinal }}
                            </span>
                          </el-popover>
                        </template>
                      </el-table-column>
                    </el-table>
                    <div class="popover-add-empl">
                      <!-- trigger="click" -->
                      <el-popover
                        placement="bottom"
                        width="500"
                        v-model="scope2.row.visible"
                        :disabled="scope.row.AuditingState != 1"
                      >
                        <div style="padding: 20px">
                          <el-row :gutter="10">
                            <el-col :span="7">
                              <el-select
                                :value="employees"
                                value-key="Key"
                                placeholder="员工"
                                size="small"
                                @change="onChangeSelectEmployees"
                              >
                                <el-option-group
                                  v-for="item in dutyEmpls"
                                  :key="item.DutyNo"
                                  :label="item.DutyName"
                                >
                                  <el-option
                                    v-for="empl in item.List"
                                    :key="empl.Key"
                                    :label="empl.EmplName"
                                    :value="empl"
                                  >
                                    <span class="empl-name">{{
                                      empl.EmplName
                                    }}</span>
                                  </el-option>
                                </el-option-group>
                              </el-select>
                            </el-col>
                            <el-col :span="4">
                              <el-input
                                v-model="emplPerfor"
                                placeholder="业绩"
                                size="small"
                              ></el-input>
                            </el-col>
                            <el-col :span="4">
                              <el-input
                                v-model="emplRoya"
                                placeholder="提成"
                                size="small"
                              ></el-input>
                            </el-col>
                            <el-col :span="9">
                              <el-button
                                type="primary"
                                size="small"
                                @click="
                                  onConfirmAddEmployee(scope.row, scope2.row)
                                "
                                >确定</el-button
                              >
                              <el-button
                                type="danger"
                                size="small"
                                @click="onCancelEmployee(scope.row, scope2.row)"
                                >取消</el-button
                              >
                            </el-col>
                          </el-row>
                        </div>
                        <div
                          slot="reference"
                          @click="
                            addEmployee(scope.row.BillGuid, scope.row.visible)
                          "
                        >
                          添加员工
                        </div>
                      </el-popover>
                    </div>
                  </div>

                  <div v-else class="three-level">
                    <el-table
                      :data="scope2.row.EmplInfoList"
                      :show-header="false"
                      :border="false"
                      size="mini"
                      v-if="scope2.row.EmplInfoList.length > 0"
                    >
                      <el-table-column
                        align="center"
                        prop="PerforFinal"
                        width="80"
                      >
                        <!-- <template slot-scope="scope3">
                          <span>
                            {{ scope3.row.PerforFinal }}
                          </span>
                        </template> -->
                      </el-table-column>
                      <el-table-column
                        align="center"
                        prop="RoyaFinal"
                        width="80"
                      >
                        <!-- <template slot-scope="scope3">
                          <span>
                            {{ scope3.row.RoyaFinal }}
                          </span>
                        </template> -->
                      </el-table-column>
                      <el-table-column align="center" width="150">
                        <template slot-scope="scope3">
                          <div>
                            <p>
                              <span class="empl-no">{{
                                scope3.row.EmplNo
                              }}</span>
                              <span>{{ scope3.row.LevelName }}</span>
                            </p>
                            <p>[{{ scope3.row.EmplName }}]</p>
                          </div>
                        </template>
                      </el-table-column>
                      <el-table-column align="center" width="80">
                        <template
                          slot-scope="scope3"
                          v-if="scope.row.BillType < 6"
                        >
                          <div>
                            <p v-if="scope.row.RecordType == 1">
                              {{
                                scope3.row.AssignMark == 1 ? "指定" : "非指定"
                              }}
                            </p>
                          </div>

                          <span>
                            {{ scope3.row.AssignRoyaFinal }}
                          </span>
                        </template>
                      </el-table-column>
                      <el-table-column align="center">
                        <template
                          slot-scope="scope3"
                          width="120"
                          v-if="scope.row.BillType < 6"
                        >
                          <span>
                            {{ scope3.row.InventRoyaFinal }}
                          </span>
                        </template>
                      </el-table-column>
                    </el-table>
                    <div class="popover-add-empl">
                      修改业绩提成请到会员还款中处理
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column align="center" width="150">
                <template slot-scope="scope">
                  {{ scope.row.ItemName }}
                </template>
              </el-table-column>
              <el-table-column align="center" width="80">
                <template slot-scope="scope">
                  {{ scope.row.ItemName }}
                </template>
              </el-table-column>
              <el-table-column align="center">
                <template slot-scope="scope" width="120">
                  {{ scope.row.ItemName }}
                </template>
              </el-table-column>
            </el-table>
            <div v-else>
              <el-popover
                placement="bottom"
                width="500"
                v-model="scope.row.visible_2"
                :disabled="scope.row.AuditingState != 1"
              >
                <div style="padding: 20px">
                  <el-row :gutter="10">
                    <el-col :span="7">
                      <el-select
                        :value="employees"
                        value-key="Key"
                        placeholder="员工"
                        size="small"
                        @change="onChangeSelectEmployees"
                      >
                        <el-option-group
                          v-for="item in dutyEmpls"
                          :key="item.DutyNo"
                          :label="item.DutyName"
                        >
                          <el-option
                            v-for="empl in item.List"
                            :key="empl.EmplGuid"
                            :label="empl.EmplName"
                            :value="empl"
                          >
                            <span class="empl-name">{{ empl.EmplName }}</span>
                          </el-option>
                        </el-option-group>
                      </el-select>
                    </el-col>
                    <el-col :span="4">
                      <el-input
                        v-model="emplPerfor"
                        placeholder="业绩"
                        size="small"
                      ></el-input>
                    </el-col>
                    <el-col :span="4">
                      <el-input
                        v-model="emplRoya"
                        placeholder="提成"
                        size="small"
                      ></el-input>
                    </el-col>
                    <el-col :span="9">
                      <el-button
                        type="primary"
                        size="small"
                        @click="onConfirmAddEmployee(scope.row, {})"
                        >确定</el-button
                      >
                      <el-button
                        type="danger"
                        size="small"
                        @click="onCancelEmployee(scope.row, {})"
                        >取消</el-button
                      >
                    </el-col>
                  </el-row>
                </div>
                <div
                  slot="reference"
                  @click="addEmployee(scope.row.BillGuid, scope.row.visible)"
                >
                  添加员工
                </div>
              </el-popover>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="业绩" align="center" width="80">
      </el-table-column>
      <el-table-column label="提成" align="center" width="80">
      </el-table-column>
      <el-table-column label="员工" align="center" width="150">
      </el-table-column>
      <el-table-column align="center" width="80">
        <template slot="header" slot-scope="scope">
          <div>指定</div>
          <div>提成</div>
        </template>
      </el-table-column>
      <el-table-column align="center" width="120">
        <template slot="header" slot-scope="scope">
          <div>创单</div>
          <div>提成</div>
        </template>
      </el-table-column>
      <el-table-column v-if="!excelDisabled" align="center" width="240">
        <template slot="header" slot-scope="scope">
          <div>操作人</div>
          <div>对单状态 / 操作</div>
        </template>
        <template slot-scope="scope">
          <el-button
            :loading="loadingSingle == scope.row.BillGuid"
            :type="scope.row.AuditingState == 1 ? 'danger' : 'success'"
            size="mini"
            @click="onMatchAnOrder(scope.row)"
          >
            <span v-if="scope.row.AuditingState == 1">对单</span>
            <i v-else class="el-icon-check"></i>
          </el-button>
          <el-button type="warning" size="mini" @click="onMeltSingle(scope.row)"
            >销单</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <EditorSoftwareDate
      ref="EditorSoftwareDate"
      @CloseDialog="onCloseSoftwareDateDialog"
    ></EditorSoftwareDate>

    <EditorNote ref="EditorNote" @CloseDialog="onCloseNoteDialog"></EditorNote>

    <EditorCalcClient
      ref="EditorCalcClient"
      :calcClientList="calcClientList"
      @CloseDialog="onCloseCalcClientDialog"
    ></EditorCalcClient>

    <EditDept
      ref="EditDept"
      :deptList="deptList"
      @success="initPageData"
    ></EditDept>

    <AloneDonaItemTableDialog
      ref="aloneDonaItemTableDialog"
    ></AloneDonaItemTableDialog>

    <RelateTimeDialog
      ref="RelateTimeDialog"
      @success="initPageData"
    ></RelateTimeDialog>

    <EditCardPerforRoya ref="EditCardPerforRoya" @success="initPageData"></EditCardPerforRoya>
  </div>
</template>

<script>
import api from "@/api/cashier.js";
import EditorSoftwareDate from "./components/editor-software-date.vue";
import EditorNote from "./components/editor-note.vue";
import EditorCalcClient from "./components/editor-calc-client.vue";
import EditDept from "./components/editor-dept.vue";
import AloneDonaItemTableDialog from "./components/alone-dona-item-table-dialog.vue";
import RelateTimeDialog from "./components/relate-time-dialog.vue";
import EditCardPerforRoya from "./components/edit-card-perfor-roya.vue";

export default {
  components: {
    EditorSoftwareDate,
    EditorNote,
    EditorCalcClient,
    EditDept,
    AloneDonaItemTableDialog,
    RelateTimeDialog,
    EditCardPerforRoya
  },

  directives: {},

  data() {
    return {
      tableMaxHeight: 0,

      beginAndEnd: "",
      auditing: "",
      admin: "",
      billType: "",
      word: "",
      storesShare: "",
      deptGuid: "",
      paymentGuid: "",
      emplGuid: "",
      levelGuid: "",
      deleteMark: 0,

      emplKey: {},
      exceptionSheetQuery: [],
      billTypeList: [],
      checkMarkList: [],
      deptList: [],
      menagerAdminList: [],
      paymentList: [],
      storesShareList: [],
      calcClientList: [],
      dutyEmpls: [],
      summary: {},
      sortInfo: {
        BillNo: 0,
        BillTime: 0,
      },
      billList: [],
      copyBillList: [],
      aloneDonaItemList: [],
      allEmpls: [],

      loadingSingle: "",

      selectAlterSoftwareDateArray: [],

      employees: {},
      emplPerfor: "",
      emplRoya: "",
      alterPerfor: "",
      alterRoya: "",
      alterAssignRoya: "",
      alterInventRoya: "",

      loading: false,
      isCheckEmpl: false,
      excelDisabled: false,
    };
  },

  computed: {
    // 消费内容特殊字体颜色
    changeExtension() {
      return function(extension) {
        let str = extension.replace(/\[BR\]/g, "<br>");
        str = str.replace(/\[BLUE\]/g, "<span class='color'>");
        str = str.replace(/\[\/BLUE\]/g, "</span>");
        return str;
      };
    },
  },

  created() {
    this.getResize();
  },

  mounted() {
    window.addEventListener("resize", this.getResize);

    this.$store.dispatch("initRxcode");
    this.beginAndEnd = this.util.getNowMonthToday();
    this.inQuireData();
    this.initPageData();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 40 + 48);
    },

    // 初始化页面数据
    async initPageData() {
      this.loading = true;
      try {
        let submitData = {
          begin_date: this.beginAndEnd,
          end_date: this.beginAndEnd,
          auditing: this.auditing === "" ? 0 : this.auditing,
          admin: this.admin,
          bill_type: this.billType === "" ? 0 : this.billType,
          word: this.word,
          stores_share: this.storesShare === "" ? -1 : this.storesShare,
          dept_guid: this.deptGuid,
          payment_guid: this.paymentGuid,
          abnormal_tpye: this.deleteMark === "" ? 0 : this.deleteMark,
          empl_guid: this.emplGuid,
          level_guid: this.levelGuid,
          //delete_mark: this.deleteMark === '' ? 0 : this.deleteMark,
        };
        let { data } = await api.getAuditingList(submitData);
        let { BillList, Summary, AloneDonaItemList } = data;
        BillList.forEach((bill) => {
          bill.visible = false;
          bill.visible_2 = false;
          bill.ItemInfoList.forEach((item) => {
            item.visible = false;
            item.EmplInfoList.forEach((empl) => {
              empl.visible_1 = false;
              empl.visible_2 = false;
              empl.visible_3 = false;
              empl.visible_4 = false;
              empl.visible_5 = false;
            });
          });
        });
        this.billList = BillList;
        this.copyBillList = JSON.parse(JSON.stringify(BillList));
        this.summary = Summary;
        this.aloneDonaItemList = AloneDonaItemList;

        this.onChangeCheckEmpl(this.isCheckEmpl);
      } catch (e) {
        // console.log(e);
      }
      this.loading = false;
    },

    // 页面条件数据
    async inQuireData() {
      try {
        let { data } = await api.getInitData();
        let {
          abnormalType,
          billType,
          checkMark,
          deptList,
          menagerAdminList,
          paymentList,
          storesShare,
          calcClientList,
          dutyEmpls,
          allEmpls,
        } = data;

        checkMark.forEach((item) => {
          if (item.Name === "") {
            item.Name = "全部";
          }
        });

        deptList.unshift({ DeptName: "无部门", DeptGuid: "" });
        dutyEmpls.unshift({
          DutyName: "全部",
          DutyNo: "all",
          List: [
            { Key: "", EmplName: "全部员工", LevelGuid: "", EmplGuid: "" },
          ],
        });

        this.exceptionSheetQuery = abnormalType;
        this.billTypeList = billType;
        this.checkMarkList = checkMark;
        this.deptList = deptList;
        this.menagerAdminList = menagerAdminList;
        this.paymentList = paymentList;
        this.storesShareList = storesShare;
        this.calcClientList = calcClientList;
        this.dutyEmpls = dutyEmpls;
        this.emplKey = dutyEmpls[0].List[0];
        // this.allEmpls = [
        //   { EmplGuid: "", EmplNo: "", EmplName: "全部员工" },
        //   ...allEmpls,
        // ];
        // console.log(dutyEmpls);
      } catch (e) {
        // console.log(e)
      }
    },

    // 仅显示选中员工的多选框改变事件（筛选水单列表）
    async onChangeCheckEmpl(event) {
      let that = this;

      let copyBillList = JSON.parse(JSON.stringify(this.copyBillList));
      // console.log(copyBillList);
      if ((await event) && this.emplGuid) {
        await copyBillList.forEach((billItem, billIndex) => {
          billItem.ItemInfoList.forEach((itemItem, itemIndex) => {
            let itemArr = [];

            itemItem.EmplInfoList.forEach((emplItem, emplIndex) => {
              if (emplItem.EmplGuid == this.emplGuid) {
                itemArr.push(emplItem);
              }

              if (itemItem.EmplInfoList.length - 1 == emplIndex) {
                itemItem.EmplInfoList = itemArr;
              }
            });
          });

          if (copyBillList.length - 1 == billIndex) {
            console.log(copyBillList);
          }
        });
      }
      await this.$nextTick(() => {
        this.billList = copyBillList;
      });
    },

    // 选中日期
    onSelectDate(event) {
      this.beginAndEnd = event;
    },

    // 查询账单
    onSearchBill() {
      this.initPageData();
    },

    // 选中水单
    handleSelectionChange(event) {
      this.selectAlterSoftwareDateArray = event;
    },

    // 账单备注
    onEditorNote(event) {
      this.$refs.EditorNote.openNote(event);
    },

    // 关闭账单备注
    onCloseNoteDialog() {
      this.initPageData();
    },

    // 打开记账日期
    onSoftWareDate() {
      if (this.selectAlterSoftwareDateArray.length === 0) {
        return this.$message({
          message: "请选择要修改的水单",
          type: "warning",
        });
      }
      this.$refs.EditorSoftwareDate.openSoftwareDate(
        this.selectAlterSoftwareDateArray
      );
    },

    // 关闭记账日期
    onCloseSoftwareDateDialog() {
      this.initPageData();
    },

    // 打开关联对话框
    onShowRelate(billItem, itemInfo, emplItem) {
      // console.log(billItem, emplItem);
      this.$refs.RelateTimeDialog.onShowDialog(billItem, itemInfo, emplItem);
    },

    // 记客数
    onCalcClient(event) {
      this.$refs.EditorCalcClient.openCalcClient(event);
    },

    // 打开单独赠送项目表格对话框
    onShowAloneDonaItemDialog() {
      this.$refs.aloneDonaItemTableDialog.onShowDialog(this.aloneDonaItemList);
    },

    // 选择员工改变事件
    onChangeEmpl(event) {
      // console.log(event);
      this.emplGuid = event.EmplGuid;
      this.levelGuid = event.LevelGuid;
    },

    onCloseCalcClientDialog() {
      this.initPageData();
    },

    onChangeSelectEmployees(event, i) {
      this.employees = event;
      // console.log(event, i);
    },

    addEmployee(event) {},

    onChangeSort(keyName) {
      this.sortInfo[keyName] = this.sortInfo[keyName] ? 0 : 1;
      this.billList.sort((a, b) => {
        if (this.sortInfo[keyName]) {
          return a[keyName] - b[keyName];
        } else {
          return b[keyName] - a[keyName];
        }
      });
    },

    // 打开添加员工对话框
    onShowAddEmployeeDialog(event, item) {},

    async onConfirmAddEmployee(event, item) {
      if (this.employees.EmplGuid == undefined) {
        return this.$message({
          message: "请选择员工",
          type: "warning",
        });
      }

      this.emplPerfor = this.emplPerfor ? Number(this.emplPerfor) : 0;
      this.emplRoya = this.emplRoya ? Number(this.emplRoya) : 0;
      // console.log(event, item, this.employees);
      try {
        if (event.BillType < 6) {
          let submitData = {
            SoftwareDate: this.util.dateFormat(
              "YYYY-mm-dd",
              new Date(event.SoftwareDate * 1000)
            ),
            BillGuid: event.BillGuid,
            ExpeItemGuid: item.InfoGuid,
            Perfor: this.emplPerfor,
            Roya: this.emplRoya,
            EmplInfo: {
              EmplGuid: this.employees.EmplGuid,
              LevelGuid: this.employees.LevelGuid,
              DutyNo: this.employees.DutyNo,
            },
          };

          let { data, errcode } = await api.addExpeEmpl(submitData);
        } else {
          let submitData = {
            BillGuid: event.BillGuid,
            EmplGuid: this.employees.EmplGuid,
            Perfor: this.emplPerfor,
            Roya: this.emplRoya,
            LevelGuid: this.employees.LevelGuid,
            DutyNo: this.employees.DutyNo,
            SoftwareDate: this.util.dateFormat(
              "YYYY-mm-dd",
              new Date(event.SoftwareDate * 1000)
            ),
          };

          let { data, errcode } = await api.addKeepEmpl(submitData);
        }

        this.employees = {};
        this.emplPerfor = "";
        this.emplRoya = "";
        this.initPageData();
      } catch (e) {}
    },

    onCancelEmployee(event, item) {
      item.visible = false;
      this.employees = {};
      this.emplPerfor = "";
      this.emplRoya = "";
    },

    async onDelectEmployee(empl, item, bill) {
      try {
        if (bill.BillType < 6) {
          let submitData = {
            SoftwareDate: this.util.dateFormat(
              "YYYY-mm-dd",
              new Date(bill.SoftwareDate * 1000)
            ),
            BillGuid: bill.BillGuid,
            ExpeItemGuid: item.InfoGuid,
            ExpeEmplGuid: empl.InfoGuid,
          };
          let { data, errcode } = await api.deleteExpeEmpl(submitData);
        } else {
          let submitData = {
            bill_guid: bill.BillGuid,
            keep_empl_guid: empl.InfoGuid,
            software_date: this.util.dateFormat(
              "YYYY-mm-dd",
              new Date(bill.SoftwareDate * 1000)
            ),
          };
          let { data, errcode } = await api.deleteKeepEmpl(submitData);
        }
        this.initPageData();
      } catch (e) {
        //TODO handle the exception
      }
    },

    async onAssignEmployee(empl, item, bill) {
      try {
        let submitData = {
          SoftwareDate: this.util.dateFormat(
            "YYYY-mm-dd",
            new Date(bill.SoftwareDate * 1000)
          ),
          BillGuid: bill.BillGuid,
          ExpeItemGuid: item.InfoGuid,
          ExpeEmplGuid: empl.InfoGuid,
          AssignMark: empl.AssignMark == 0 ? 1 : 0,
        };
        let { data, errcode } = await api.updateExpeAssign(submitData);
        this.initPageData();
      } catch (e) {
        //TODO handle the exception
      }
    },

    // 修改业绩
    async onConfirmPerfor(empl, item, bill) {
      console.log(empl, item, bill);
      try {
        if (bill.BillType < 6) {
          let submitData = {
            SoftwareDate: this.util.dateFormat(
              "YYYY-mm-dd",
              new Date(bill.SoftwareDate * 1000)
            ),
            BillGuid: bill.BillGuid,
            ExpeItemGuid: item.InfoGuid,
            ExpeEmplGuid: empl.InfoGuid,
            Perfor: this.alterPerfor,
          };
          let { data, errcode } = await api.updateExpePerforRoya(submitData);
        } else {
          let submitData = {
            BillGuid: bill.BillGuid,
            KeepEmplGuid: empl.InfoGuid,
            Perfor: this.alterPerfor,
            SoftwareDate: this.util.dateFormat(
              "YYYY-mm-dd",
              new Date(bill.SoftwareDate * 1000)
            ),
          };
          let { data } = await api.updateKeepEmplPerfor(submitData);
        }
        this.alterPerfor = "";
        this.initPageData();
      } catch (e) {}
    },

    // 取消修改业绩
    onCancelPerfor(empl, item, bill) {
      this.alterPerfor = "";
      empl.visible_1 = false;
    },

    async onConfirmRoya(empl, item, bill) {
      try {
        if (bill.BillType < 6) {
          let submitData = {
            SoftwareDate: this.util.dateFormat(
              "YYYY-mm-dd",
              new Date(bill.SoftwareDate * 1000)
            ),
            BillGuid: bill.BillGuid,
            ExpeItemGuid: item.InfoGuid,
            ExpeEmplGuid: empl.InfoGuid,
            Roya: this.alterRoya,
          };
          let { data } = await api.updateExpeRoya(submitData);
        } else {
          let submitData = {
            BillGuid: bill.BillGuid,
            KeepEmplGuid: empl.InfoGuid,
            Roya: this.alterRoya,
          };
          let { data } = await api.updateKeepEmplRoya(submitData);
        }
        this.alterRoya = "";
        this.initPageData();
      } catch (e) {}
    },

    onCancelRoya(empl, item, bill) {
      this.alterRoya = "";
      empl.visible_4 = false;
    },

    // 指定提成
    async onConfirmAssignRoya(empl, item, bill) {
      try {
        let submitData = {
          SoftwareDate: this.util.dateFormat(
            "YYYY-mm-dd",
            new Date(bill.SoftwareDate * 1000)
          ),
          BillGuid: bill.BillGuid,
          ExpeItemGuid: item.InfoGuid,
          ExpeEmplGuid: empl.InfoGuid,
          AssignRoya: this.alterAssignRoya,
        };
        let { data } = await api.updateExpeAssignRoya(submitData);
        this.alterAssignRoya = "";
        this.initPageData();
      } catch (e) {}
    },

    onCancelAssignRoya(empl, item, bill) {
      this.alterAssignRoya = "";
      empl.visible_2 = false;
    },

    // 创单提成
    async onConfirmInventRoya(empl, item, bill) {
      // console.log(empl, item, bill);
      return;
      try {
        let submitData = {
          SoftwareDate: this.util.dateFormat(
            "YYYY-mm-dd",
            new Date(bill.SoftwareDate * 1000)
          ),
          BillGuid: bill.BillGuid,
          ExpeItemGuid: item.InfoGuid,
          ExpeEmplGuid: empl.InfoGuid,
          InventRoya: this.alterInventRoya,
        };
        let { data } = await api.updateExpeInventRoya(submitData);
        this.alterInventRoya = "";
        this.initPageData();
      } catch (e) {}
    },

    onCancelInventRoya(empl, item, bill) {
      empl.visible_3 = false;
      this.alterInventRoya = "";
    },

    onShowEditDept(billInfo, itemInfo) {
      let data = {
        DeptName: itemInfo.DeptName,
        InfoGuid: itemInfo.InfoGuid,
        BillGuid: billInfo.BillGuid,
        SoftwareDate: billInfo.SoftwareDate,
      };
      this.$refs.EditDept.onShowDialog(data);
    },

    onShowEditPerfor(billInfo, billIndex){
      // console.log(billInfo, billIndex);
      this.$refs.EditCardPerforRoya.onShowDialog(billInfo)
    },

    // 对单
    async onMatchAnOrder(event) {
      this.loadingSingle = event.BillGuid;
      try {
        let submitData = {
          bill_guid: event.BillGuid,
          software_date: this.util.dateFormat(
            "YYYY-mm-dd",
            new Date(event.SoftwareDate * 1000)
          ),
          auditing: event.AuditingState == 1 ? 2 : 1,
          record_type: event.RecordType,
        };
        let { data, errcode } = await api.handle(submitData);
        if (errcode == 0) {
          this.initPageData();
        }
      } catch (e) {}
      this.loadingSingle = "";
    },

    // 销单
    async onMeltSingle(event) {
      this.$confirm(`是否销单‘${event.BillNo}’`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            let submitData = {
              bill_guid: event.BillGuid,
              software_date: this.util.dateFormat(
                "YYYY-mm-dd",
                new Date(event.SoftwareDate * 1000)
              ),
              record_type: event.RecordType,
            };
            let { data, errcode } = await api.reverseBill(submitData);
            if (errcode == 0) {
              this.$message({
                message: "销单成功!",
                type: "success",
              });
              this.initPageData();
            } else {
              this.$message.error(errmsg);
            }
          } catch (e) {
            console.error(e);
          }
        })
        .catch((err) => {});
    },

    twoDimensionalArray(rowspan = 0, colspan = 0) {
      //{ rowspan: 0 , colspan: 0 }  零是不输出,  { rowspan: 1 , colspan: 1 } 一是输出

      // 根据数据结构定义二维数组
      // let arr = [];
      // for(var row = 0; row < 10; row++){
      // 	arr[row] = [];
      // 	for(var col = 0; col < 10; col++){
      // 		arr[row][col] = {
      // 			rowspan: 1,
      // 			colspan: 1
      // 		}
      // 	}
      // }

      // 修改二维数组
      // for(var row = 0; row < 10; row++){
      // 	for(var col = 0; col < 10; col++){
      // 		if(col == 4){
      // 			arr[row][col] = {
      // 				rowspan: 1,
      // 				colspan: 5
      // 			}
      // 		}else if(col > 4 && col < 9){
      // 			arr[row][col] = {
      // 				rowspan: 0,
      // 				colspan: 0
      // 			}
      // 		}
      // 	}
      // }

      let arr = [];
      for (var row = 0; row < this.billList.length; row++) {
        arr[row] = [];
        for (var col = 0; col < 10; col++) {
          arr[row][col] = {
            rowspan: 1,
            colspan: 1,
          };
        }
      }

      for (var row = 0; row < this.billList.length; row++) {
        for (var col = 0; col < 10; col++) {
          if (col == 4) {
            arr[row][col] = {
              rowspan: 1,
              colspan: 5,
            };
          } else if (col > 4 && col < 9) {
            arr[row][col] = {
              rowspan: 0,
              colspan: 0,
            };
          }
        }
      }

      return arr[rowspan][colspan];
    },

    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      //return this.twoDimensionalArray(rowIndex, columnIndex);

      if (columnIndex == 5) {
        return {
          rowspan: 1, //rowIndex % 2 == 0 ? 2 : 0,
          colspan: 6, //rowIndex % 2 == 0 ? 5 : 0
        };
      } else if (columnIndex > 5 && columnIndex < 11) {
        return {
          rowspan: 0,
          colspan: 0,
        };
      }
    },

    objectSpanMethodItem({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 1) {
        return {
          rowspan: 1, //rowIndex % 2 == 0 ? 2 : 0,
          colspan: 4, //rowIndex % 2 == 0 ? 5 : 0
        };
      } else if (columnIndex > 1) {
        return {
          rowspan: 0,
          colspan: 0,
        };
      }
    },
  },
};
</script>

<style lang="less" scoped>
.audit {
  height: 100%;
  width: 100%;
  box-sizing: border-box;

  .df-row {
    display: flex;

    > div {
      // flex: 1;
      width: 120px;
      margin-right: 10px;
    }

    .select-empl {
      // flex: 0.5;
      display: flex;
      align-items: center;
    }

    .date-picker {
      flex: unset;
      width: 130px;
    }
  }

  .statistical {
    .summary_number {
      flex: unset;
      min-width: 935px;
      // max-width: 1060px;
    }

    .el-collapse,
    .el-collapse-item__wrap {
      border: none;
    }

    ::v-deep .el-collapse-item__header {
      height: 20px;
      line-height: 20px;
      padding-bottom: 10px;
      border: none;
      cursor: unset !important;
    }

    ::v-deep .el-collapse-item__arrow {
      display: none !important;
    }

    .el-collapse-item__content {
      padding-bottom: 8px;
    }

    .alone-dona {
      // flex: 0.7;
      color: red !important;
      // padding-bottom: 10px;
      margin-right: 10px;
    }

    .text {
      padding-bottom: 10px;
      .value {
        padding: 0 5px;
        font-size: 16px;
        font-weight: bold;
        color: #428bca;
      }
    }

    .btn-box {
      // width: 100%;
      // max-width: 675px;
      display: flex;
      flex-direction: row;
      // justify-content: right;
      // margin-left: 150px;
      line-height: 20px;

      .el-input {
        max-width: 240px !important;
        width: 100%;
        margin-right: 10px;
      }

      // justify-content: flex-end;
    }
  }

  ::v-deep .el-table {
    font-size: 12px;
    td .cell {
      padding: 0;
      line-height: 17px;
      .total {
        width: 100%;
        padding-bottom: 5px;
        margin-bottom: 5px;
        border-bottom: 1px dashed #ccc;
        .disc {
          margin-right: 5px;
        }
      }
    }

    .el-table-column--selection .cell {
      display: flex;
      justify-content: center;
    }

    .el-icon-edit-outline {
      padding-left: 5px;
      font-weight: bold;
    }

    .sex {
      margin-right: 5px;
    }

    .type {
      color: #0a8;
    }

    .active {
      color: red;
    }

    .payment-money {
      margin-right: 5px;
    }

    .payment-name {
    }

    .second-level
      > .el-table
      > .el-table__body-wrapper
      > table
      > tbody　
      > tr:last-child
      > td {
      border-bottom: 0;
    }

    .second-level
      > .el-table
      > .el-table__body-wrapper
      > table
      > tbody　
      > tr
      > td:last-child,
    .three-level
      > .el-table
      > .el-table__body-wrapper
      > table
      > tbody　
      > tr
      > td:last-child {
      border-right: 0;
    }

    .three-level .empl-no {
      padding-right: 10px;
    }

    .el-table {
      .cell {
        padding: 0;
      }
      .el-table {
        // tbody tr:last-child td{
        // 	border-bottom: 0;
        // }
      }
    }

    .el-table::before {
      height: 0;
    }

    .dept-name {
      cursor: pointer;
    }

    .bill-head {
      .flex-row;
      justify-content: center;
      width: 100%;

      > div {
        .flex-row;
        justify-content: center;
        margin: 0 10px;
        cursor: pointer;

        .icon-box {
          .flex-col;
          margin-left: 2px;
          color: #ddd;
          font-size: 14px;

          i {
            line-height: 0;
            padding: 3.5px 0;
          }
        }
      }

      .sort-active {
        color: #409eff;
      }
    }
  }

  .color {
    color: #0ae;
  }

  .appo {
    color: limegreen;
  }

  .not-appo {
    color: tomato;
  }

  .pointer {
    cursor: pointer;
  }

  .el-table--border,
  .el-table--group,
  .el-table--border td,
  .el-table--border th {
    border-color: #dddddd;
  }
}

.el-table::before {
  background-color: #dddddd;
}
.el-table::after {
  background-color: #dddddd;
}

.header-row-class-name {
  color: #363636;
}

.el-select-group__title {
  //font-weight: bold;
  font-size: 14px;
  font-weight: bold !important;
  color: #000000 !important;
}

.el-select-dropdown__item {
  font-size: 12px !important;
}

.empl-name {
  margin-left: 10px;
}

.popover-add-empl {
  padding-top: 12px;
}

.el-popconfirm {
  padding: 10px;
  .el-popconfirm__main {
    padding-bottom: 10px;
  }
}
</style>
